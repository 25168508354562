<template>
  <v-data-table
    :headers="headers"
    :items="ips"
    :loading="loading"
    loading-text="Загрузка... Пожалуйста подождите"
    :items-per-page="100"
  >
    <template v-slot:item.nick="{ item }">
      <span class="font-weight-bold">{{ item.nick }} </span>
    </template>
    <template v-slot:item.ip="{ item }">
      <span class="font-weight-bold">{{ item.ip }} </span>
    </template>
    <template v-slot:item.date="{ item }">
      {{ item.date | moment("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template v-slot:item.nick="{ item }">
      <router-link tag="div" :to="`/users/${item.id}`">
        <v-btn>{{ item.nick }}</v-btn>
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import { LoginService } from "@/services/logins.service";
export default {
  name: "View",
  data: () => ({
    ips: [],
    headers: [
      { text: "IP", value: "ip" },
      { text: "Пользователь", value: "nick" },
      { text: "Время", value: "date" }
    ],
    loading: true
  }),
  methods: {
    getUserByIp() {
      LoginService.getUsersByIp({
        ip: this.$route.params.ip
      }).then(data => {
        this.ips = data;
        this.loading = false;
      });
    }
  },
  created() {
    this.getUserByIp();
  }
};
</script>

<style scoped></style>
