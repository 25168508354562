import { api } from "@/instances/http";

class Login {
  getUsersByIp(params = {}) {
    return api.post("/login/getUsersByIp", { ...params }).then(response => {
      return response.data;
    });
  }
}

export const LoginService = new Login();
